import React, { useState } from 'react';
import './ForgotPassword.css';
import { sendPasswordReset } from '../../Components/Firebase/Firebase';
import { useTranslation } from 'react-i18next';

const ForgotPassword = ({ setLoading, userLanguage }) => {
  const [email, setEmail] = useState('');

  const { t } = useTranslation();

  const handleSubmit = () => {
    setLoading(true);
    if (email !== "") {
      sendPasswordReset(email);
      setEmail("");

    } else {
      alert(t('enter_your_personal_email'))

    }
    setLoading(false);

  };

  return (
    <>
      <div className="forgot-password-container" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
        <div className="forgot-password-background"></div>
        <div className="forgot-password-details">
          <div className="forgot-password-contant">
            <h4>{t('forgot_your_password')}</h4>
            <p>{t('enter_your_email_address_to_reset_your_password')}</p>

            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              placeholder={t('email')}
              className={`styled-input`}
              style={{ width: 250 }}
            />

          </div>
          <button
            onClick={handleSubmit}
            className="package-button"
            style={{ color: "#013B46", borderColor: "#013B46" }}
          >{t('send_password_reset_link')}
          </button>
        </div>
      </div>
      {/* <Footer userLanguage={userLanguage}/>  */}
    </>
  );
};

export default ForgotPassword;
