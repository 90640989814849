import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import PhotoGallery from '../Screens/PhotoGallery/PhotoGallery';
import VideoGallery from '../Screens/VideoGallery/VideoGallery';
import HomeScreen from '../Screens/HomeScreen/HomeScreen';
import PackagesOffer from '../Screens/PackagesOffer/PackagesOffer';
import SignUpForm from '../Screens/SignUpForm/SignUpForm';
import SignInForm from '../Screens/SignInForm/SignInForm';
import './Navigation.css';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import AboutPersonComponent from '../Screens/AboutPersonComponent/AboutPersonComponent';

import { useDispatch } from 'react-redux';
import { getUserData } from '../Redux/actions/actions';

import PersoneHistory from '../Screens/PersoneHistory/PersoneHistory';
import { app, logout } from '../Components/Firebase/Firebase';
import EnterWithQrCode from '../Screens/EnterWithQrCode/EnterWithQrCode';
import SettingsPersoneHistory from '../Screens/SettingsPersoneHistory/SettingsPersoneHistory';
import ErrorPage from '../Screens/ErrorPage/ErrorPage';
import ForgotPassword from '../Screens/ForgotPassword/ForgotPassword';
import DiscountForm from '../Screens/DiscountForm/DiscountForm';
import { useTranslation } from 'react-i18next';
import { VscThreeBars } from "react-icons/vsc";
import { IoExitOutline } from "react-icons/io5";

import ContactForm from '../Screens/ContactForm/ContactForm';
import PageNoFound from '../Screens/PageNoFound/PageNoFound';
import GenerateLogoQRCode from '../Components/GenerateLogoQRCode/GenerateLogoQRCode';

import { IoPersonCircleOutline } from "react-icons/io5";
import Alert from '../Components/Alert/Alert';
import { getAuth } from 'firebase/auth';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import PrivacyPolicy from '../Components/PrivatePolicy/PrivatePolicy-Isr';

import small_logo_primary_ru from '../Images/logos/logo_transparant_ru.png';
import small_logo_primary_he from '../Images/logos/logo_transparant_he.png';
import small_logo_primary_en from '../Images/logos/logo_transparant_en.png';
import PublicAgreementIsr from '../Components/PrivatePolicy/PublicAgreement-isr';
import CreateDefaultUser from '../Screens/EnterWithQrCode/CreateDefaultUser/CreateDefaultUser';
// import UpdateEmail from '../Screens/EnterWithQrCode/UpdateEmail/UpdateEmail';
import ChangeAccount from '../Screens/SignInForm/ChangeAccount';
import Spinner from '../Components/Spinner/Spinner-old';
import ScrollToTopButton from '../Components/ScrollToTopButton/ScrollToTopButton';
import OpenWhatsApp from '../Components/OpenWhatsApp/OpenWhatsApp';
import FAQSection from '../Screens/FAQItem/FAQItem';

const Navigation = () => {
    const [loading, setLoading] = useState(false);

    //Get user language from local storage
    const storedUserLanguage = localStorage.getItem('userLanguage');
    const initialUserLanguage = storedUserLanguage || 'he';

    const [userLanguage, setUserLanguage] = useState(initialUserLanguage);
    const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false); // Стейт для определения мобильного устройства
    const navigate = useNavigate();

    const { t } = useTranslation();

    const location = useLocation();
    const dispatch = useDispatch();

    const [loadingUserData, setLoadingUserData] = useState(true);

    //get userId from firebase
    const auth = app ? getAuth(app) : null;
    const currentUser = auth?.currentUser?.uid;

    //get userId from localStorage
    // const shortenedUserId = localStorage?.getItem('userId');
    const shortenedUserId = sessionStorage?.getItem('userId') || localStorage?.getItem('userId');

    //if currentUser uncludes 
    const getId = currentUser?.includes(shortenedUserId)

    if (getId) {
        dispatch(getUserData(currentUser));
    }

    const storedUserId = shortenedUserId?.substring(0, 10);


    useEffect(() => {
        if (storedUserId) {
            setLoadingUserData(true); // Устанавливаем флаг загрузки данных в true

            dispatch(getUserData(storedUserId))
                .then(() => setLoadingUserData(false)); // После завершения загрузки данных устанавливаем флаг загрузки в false
        } else {
            setLoadingUserData(false); // Если нет сохраненного идентификатора пользователя, просто устанавливаем флаг загрузки в false
        }
    }, [dispatch, storedUserId]);

    useEffect(() => {
        // Прокручиваем страницу вверх при изменении местоположения (переходе между вкладками)
        window.scrollTo(0, 0);
    }, [location.pathname]);

    // get user with qr code
    const [enterWithQrCode, setEnterWithQrCode] = useState(true);
    const [retryCount, setRetryCount] = useState(0); // Добавляем состояние для подсчета попыток

    const MAX_RETRIES = 2;

    // useEffect(() => {
    //     const handleResize = () => {
    //         setIsMobile(window.innerWidth < 768);
    //     };

    //     handleResize();

    //     window.addEventListener('resize', handleResize);

    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);
    // get all users from firebase firestore


    const getAllUserKeys = async () => {
        const db = getFirestore();
        const usersCollection = collection(db, 'users');
        try {
            const querySnapshot = await getDocs(usersCollection);
            const keys = [];
            querySnapshot.forEach((doc) => {
                keys.push(doc.id);
            });
            return keys;
        } catch (error) {
            console.error('Error getting user keys:', error);
            return [];
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getUserDataWithRetry = async (userId) => {
        try {
            const userData = await dispatch(getUserData(userId));
            return userData;
        } catch (error) {
            console.error('Error loading user data:', error);
            if (retryCount < MAX_RETRIES) {
                setRetryCount(retryCount + 1);
                // console.log(`Retrying to load user data. Retry ${retryCount} of ${MAX_RETRIES}...`);
                setTimeout(() => getUserDataWithRetry(userId), 5000);
            } else {
                // console.error('Max retry count exceeded. Unable to load user data.');
            }
        }
    };

    // Проверяем наличие QR-кода в URL и извлекаем id только в этом случае
    const currentUrl = window.location.href;
    const userEnterWithQrCode = currentUrl.includes("EnterWithQrCode");
    let id = null;

    if (userEnterWithQrCode) {
        id = new URL(currentUrl).pathname.split('/')[1];
    }

    // Получаем все ключи пользователей и обрабатываем их
    getAllUserKeys().then((keys) => {
        if (id) {
            localStorage.setItem('qrCodeId', id);
            let foundKey = null;

            keys.some(key => {
                if (key.includes(id)) {
                    foundKey = key;
                    return true;
                }
                return false;
            });

            if (foundKey !== null && foundKey !== undefined) {
                dispatch(logout)
                getUserDataWithRetry(foundKey).then(() => setEnterWithQrCode(false));
            } else {
                console.error("FoundKey is null or undefined.");
                window.location.reload(); // Обновляем страницу в случае неудачи
            }
        } else {
            // console.error("QR code not found in URL.");
            // Добавьте здесь логику, если QR-код отсутствует в URL
        }
    }).catch((error) => {
        console.error('Error getting user keys:', error);
        if (error.code === "resource-exhausted") {
            console.error('Firebase quotas exhausted. Please check your usage and quotas.');
            // Добавить здесь логику для случая, когда квоты Firebase исчерпаны
        } else {
            console.error('Unknown error occurred:', error);
            // Добавьте здесь обработку других возможных ошибок
        }
    });

    const isGalleryPage =
        location.pathname.includes("/AboutPersonComponent") ||
        location.pathname.includes("/PersoneHistory") ||
        location.pathname.includes("/PhotoGallery") ||
        location.pathname.includes("/VideoGallery") ||
        location.pathname.includes("/SettingsPersoneHistory");

    const isEnterWithQrCode = location.pathname.includes("/EnterWithQrCode");

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const closeBurgerMenu = useCallback(() => {
        setBurgerMenuOpen(false);
    }, []);

    const closeBurgerMenuOnOutsideClick = useCallback(
        (event) => {
            if (
                burgerMenuOpen &&
                event.target.closest('.burger-menu-container') === null
            ) {
                closeBurgerMenu();
            }
        },
        [burgerMenuOpen, closeBurgerMenu]
    );

    useEffect(() => {
        document.addEventListener('click', closeBurgerMenuOnOutsideClick);

        return () => {
            document.removeEventListener('click', closeBurgerMenuOnOutsideClick);
        };
    }, [closeBurgerMenuOnOutsideClick]);

    const [alertVisible, setAlertVisible] = useState(false);

    const handleLogout = useCallback(() => {
        setAlertVisible(true);
    }, []);

    const onConfirm = () => {
        logout(dispatch);
        setAlertVisible(false);
        navigate(`/${userLanguage}`);
    };

    const currentPath = location.pathname;

    // const envTest = window.location.hostname === "localhost";

    const pathWithLanguage = `/${userLanguage}`;

    useEffect(() => {
        if (currentPath === '/') {
            setLoading(true);
            navigate(pathWithLanguage);
            setLoading(false);
        }
    }, [navigate, currentPath, pathWithLanguage, userLanguage]);

    //check if currentPath indludes undefined
    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath.includes("undefined")) {
            navigate('/Error');
        }
    }, [location.pathname, navigate]);

    const contactRef = useRef(null);

    const scrollToContactForm = () => {
        navigate('/');
        setTimeout(() => {
            if (contactRef.current) {
                contactRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100); // Добавляем задержку, чтобы убедиться, что компоненты отрендерились
    };




    return (
        <>
            {loading ?
                <Spinner loading={loading} />
                :
                <>
                    {alertVisible && (
                        <Alert
                            title={(t('are_you_sure'))}
                            text={(t('do_you_want_to_log_out_of_your_account'))}
                            icon={'question'}
                            showCancelButton={true}
                            confirmButtonText={t('yes')}
                            onConfirm={onConfirm}
                            onCancel={() => setAlertVisible(false)}
                            setAlertVisible={setAlertVisible}
                            alertVisible={alertVisible}

                        />
                    )}

                    <nav
                        dir={userLanguage?.includes("he") ? "rtl" : "ltr"}
                        className={`${userLanguage?.includes('he') ? "nav " : "nav"}`}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Link to={`/${userLanguage}`}>
                                    {userLanguage?.includes('he') ?
                                        <div className="company-name" style={{marginRight: 25}}>
                                            <img
                                                src={small_logo_primary_he}
                                                alt="logo"
                                                loading="eager"
                                                style={{ width: 80, height: 80 }}
                                            />
                                        </div>
                                        : userLanguage?.includes('en') ?
                                            <div className="company-name" style={{marginLeft: 25}}>
                                                <img
                                                    src={small_logo_primary_en}
                                                    alt="logo"
                                                    loading="eager"
                                                    style={{ width: 80, height: 80 }}
                                                />
                                            </div> :
                                            <div className="company-name" style={{marginLeft: 25}}>
                                                <img
                                                    src={small_logo_primary_ru}
                                                    alt="logo"
                                                    loading="eager"
                                                    style={{ width: 80, height: 80 }}
                                                />
                                            </div>
                                    }
                                </Link>
                                <div className="company-name">
                                    <p>QrMoment</p>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                {!isGalleryPage && !isEnterWithQrCode && (
                                    <>
                                        <Link to={`/${userLanguage}`} className={location.pathname === `/${userLanguage}` ? 'current-page' : 'navigation-none-container'}>{t('home_page').toUpperCase()}</Link>
                                        <Link to="/PackagesOffer" className={location.pathname === '/PackagesOffer' ? 'current-page' : 'navigation-none-container'}>{t('services').toUpperCase()}</Link>
                                        <Link to="/SignInForm" className={location.pathname === '/SignInForm' ? 'current-page' : 'navigation-none-container'}>{t('authorization').toUpperCase()}</Link>
                                        {/* <Link to="/ContactForm" className={location.pathname === '/ContactForm' ? 'current-page' : 'navigation-none-container'}>{t('contactUs').toUpperCase()}</Link> */}
                                        <Link to="/" onClick={scrollToContactForm} className={location.pathname === '/ContactForm' ? 'current-page' : 'navigation-none-container'}>
                                            {t('contactUs').toUpperCase()}
                                        </Link>
                                        <div className='display-languages'>
                                            <LanguageSelector
                                                userLanguage={userLanguage}
                                                setUserLanguage={setUserLanguage}
                                                setBurgerMenuOpen={setBurgerMenuOpen} />
                                        </div>
                                    </>
                                )}
                                {isGalleryPage && !isEnterWithQrCode && (
                                    <>
                                        <Link to={`/${storedUserId}/PersoneHistory`} className={location.pathname === `/${storedUserId}/PersoneHistory` ? 'current-page' : 'navigation-none-container'}>{t('biography')}</Link>
                                        <Link to={`/${storedUserId}/PhotoGallery`} className={location.pathname === `/${storedUserId}/PhotoGallery` ? 'current-page' : 'navigation-none-container'}>{t('photo')}</Link>
                                        <Link to={`/${storedUserId}/VideoGallery`} className={location.pathname === `/${storedUserId}/VideoGallery` ? 'current-page' : 'navigation-none-container'}>{t('video')}</Link>
                                        <Link to={`/${storedUserId}/SettingsPersoneHistory`} className={location.pathname === `/${storedUserId}/SettingsPersoneHistory` ? 'current-page' : 'navigation-none-container'}>{t('profile')}</Link>
                                        <Link onClick={handleLogout} className={location.pathname === `/${userLanguage}` ? 'current-page' : 'navigation-none-container'}>{t('exit')}</Link>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className='burger-menu-container' dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
                            <div className="burger-menu" onClick={() => setBurgerMenuOpen(!burgerMenuOpen)}>
                                <VscThreeBars size={30} />
                            </div>

                            <Link to={`/${userLanguage}`}>
                                <p className="burger-menu-company-name">QrMoment</p>
                            </Link>

                            {storedUserId === null || storedUserId === undefined ?
                                <Link to="/SignInForm">
                                    <IoPersonCircleOutline color="black" size="30" />
                                </Link>
                                :
                                <span className='navigation-exit'>
                                    <IoExitOutline size={30} onClick={handleLogout}
                                    />

                                </span>
                            }
                        </div>


                        {burgerMenuOpen && (
                            <div className={userLanguage === 'he' ? "burger-menu-data burger-menu-data-hebrew" : "burger-menu-data"}>
                                {isEnterWithQrCode || !storedUserId ? (
                                    <>
                                        <Link to={`/${userLanguage}`} onClick={closeBurgerMenu}>{t('home_page')}</Link>
                                        <Link to="/PackagesOffer" onClick={closeBurgerMenu}>{t('services')}</Link>
                                        <Link to="/SignInForm" onClick={closeBurgerMenu}>{t('authorization')}</Link>
                                        <Link to="/ContactForm" onClick={closeBurgerMenu}>{t('contactUs')}</Link>
                                        {/* <Link to="/" onClick={scrollToContactForm} className={location.pathname === '/ContactForm' ? 'current-page' : 'navigation-none-container'}>
                                            {t('contactUs')}
                                        </Link> */}
                                        <div style={{ margin: 'auto' }}>
                                            <LanguageSelector
                                                userLanguage={userLanguage}
                                                setUserLanguage={setUserLanguage}
                                                setBurgerMenuOpen={setBurgerMenuOpen} />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <Link to={`/${storedUserId}/PersoneHistory`} onClick={closeBurgerMenu}>{t('biography')}</Link>
                                        <Link to={`/${storedUserId}/PhotoGallery`} onClick={closeBurgerMenu}>{t('photo')}</Link>
                                        <Link to={`/${storedUserId}/VideoGallery`} onClick={closeBurgerMenu}>{t('video')}</Link>
                                        <Link to={`/${storedUserId}/SettingsPersoneHistory`} onClick={closeBurgerMenu}>{t('profile')}</Link>
                                        <Link onClick={handleLogout}>{t('exit')}</Link>
                                        <div style={{ margin: 'auto' }}>
                                            <LanguageSelector
                                                userLanguage={userLanguage}
                                                setUserLanguage={setUserLanguage}
                                                setBurgerMenuOpen={setBurgerMenuOpen} />
                                        </div>
                                    </>
                                )}
                            </div>
                        )}

                        <div className={
                            window.location.pathname.includes('PersoneHistory')
                                ? 'contact-nav-container-home-screen'
                                : 'contact-nav-container'
                        }>
                            {storedUserId !== undefined && !isGalleryPage && !isEnterWithQrCode && (
                                <>
                                    <IoPersonCircleOutline
                                        className='phone-style'
                                        color="003B46"
                                        size="50"
                                        onClick={() => navigate(`/${storedUserId}/PersoneHistory`)} />
                                </>
                            )}
                        </div>

                        <ScrollToTopButton userLanguage={userLanguage} />
                        <OpenWhatsApp userLanguage={userLanguage} />

                    </nav>

                    <Routes>
                        <Route path={pathWithLanguage} element={<HomeScreen userLanguage={userLanguage} setLoading={setLoading} contactRef={contactRef} isMobile={isMobile} />} />
                        <Route path="/SignUpForm" element={<SignUpForm userLanguage={userLanguage} setLoading={setLoading} />} />
                        <Route path="/SignInForm" element={<SignInForm userLanguage={userLanguage} setLoading={setLoading} />} />
                        <Route path="/ChangeAccount" element={<ChangeAccount userLanguage={userLanguage} setLoading={setLoading} />} />
                        <Route path="/PackagesOffer" element={<PackagesOffer userLanguage={userLanguage} setLoading={setLoading} />} />
                        <Route path="/Forgot-password" element={<ForgotPassword userLanguage={userLanguage} setLoading={setLoading} />} />
                        <Route path="/Error" element={<ErrorPage userLanguage={userLanguage} />} />
                        <Route path="/ContactForm" element={<ContactForm userLanguage={userLanguage} setLoading={setLoading} isMobile={isMobile} />} />
                        <Route path="/PrivatePolicy" element={<PrivacyPolicy userLanguage={userLanguage} setLoading={setLoading} />} />
                        {/* <Route path="/UpdateEmail" element={<UpdateEmail userLanguage={userLanguage} setLoading={setLoading} />} /> */}
                        <Route path="SignUpForm/PublicAgreement" element={<PublicAgreementIsr userLanguage={userLanguage} setLoading={setLoading} />} />
                        <Route path="/FAQSection" element={<FAQSection userLanguage={userLanguage} setLoading={setLoading} />} />
                        <Route path="*" element={loadingUserData | enterWithQrCode ? <Spinner loading={true} /> : <PageNoFound loadingUserData={loadingUserData} setLoading={setLoading} />} />
                        
                        {/* Generate logo qrcode */}
                        <Route path="/GenerateLogoQRCode" element={<GenerateLogoQRCode userLanguage={userLanguage} setLoading={setLoading} />} />

                        {/* Create discount  */}
                        <Route path="/DiscountForm" element={<DiscountForm userLanguage={userLanguage} setLoading={setLoading} />} />

                        {/* Create default user */}
                        {/* {envTest && ( */}
                        <>
                            <Route path="/_:_CreateDefaultUser" element={<CreateDefaultUser userLanguage={userLanguage} setLoading={setLoading} />} />
                        </>
                        {/* )} */}
                        <>
                            {!loadingUserData && (
                                <>
                                    <Route path={`/${storedUserId}/PersoneHistory`} element={<PersoneHistory userLanguage={userLanguage} storedUserId={storedUserId} setLoading={setLoading} />} />
                                    <Route path={`/${storedUserId}/PhotoGallery`} element={<PhotoGallery userLanguage={userLanguage} storedUserId={storedUserId} setLoading={setLoading} />} />
                                    <Route path={`/${storedUserId}/VideoGallery`} element={<VideoGallery userLanguage={userLanguage} setLoading={setLoading} />} />
                                    <Route path={`/${storedUserId}/SettingsPersoneHistory`} element={<SettingsPersoneHistory userLanguage={userLanguage} storedUserId={storedUserId} setLoading={setLoading} isMobile={isMobile} />} />
                                    <Route path={`${storedUserId}/AboutPersonComponent`} element={<AboutPersonComponent userLanguage={userLanguage} setLoading={setLoading} isMobile={isMobile} />} />
                                    <Route path="/Error" element={<ErrorPage />} />
                                    <Route path="*" element={loadingUserData | enterWithQrCode ? <Spinner loading={true} /> : <PageNoFound loadingUserData={loadingUserData} setLoading={setLoading} />} />

                                </>
                            )}
                        </>

                        {!enterWithQrCode && (
                            <>
                                <Route path={`/${id}/EnterWithQrCode`} element={<EnterWithQrCode userLanguage={userLanguage} setLoading={setLoading} scrollPosition={scrollPosition} />} />
                                <Route path="*" element={loadingUserData | enterWithQrCode ? <Spinner loading={true} /> : <PageNoFound loadingUserData={loadingUserData} setLoading={setLoading} />} />

                            </>
                        )}
                    </Routes>

                </>
            }
        </>
    );
}

export default Navigation;
