import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import './Alert.css';
import { GoQuestion } from "react-icons/go";

const Alert = ({ title, text, onConfirm, onCancel, icon, confirmButtonText, showCancelButton }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(true);

    const handleConfirm = () => {
        setIsOpen(false);
        onConfirm();
    };

    const handleCancel = () => {
        setIsOpen(false);
        onCancel && onCancel();
    };

    useEffect(() => {
        setIsOpen(true);
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCancel}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 9999 // Установите z-index для overlay (подложки)
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '8px',
                    maxWidth: '800px',
                    padding: '40px',
                    textAlign: 'center',
                    // backgroundColor: '#fff',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    zIndex: 100000,
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                },
            }}
        >
            <div style={{ textAlign: 'center' }}>
                <GoQuestion size={100} color='#87adbd' />

            </div>
            <h2>{title}</h2>
            <p>{text}</p>
            {!showCancelButton ? (
                <div style={{textAlign: 'center'}}>
                    <button style={{ width: 200 }} onClick={handleConfirm} className="confirm-button">
                        {confirmButtonText}
                    </button>
                </div>
            )
                :
                <button onClick={handleConfirm} className="confirm-button">
                    {confirmButtonText}
                </button>
            }
            {showCancelButton && (
                <button onClick={handleCancel} className="cancel-button">
                    {t('cancel')}
                </button>
            )}

        </Modal>
    );
};

export default Alert;
