import React, { useEffect } from 'react';
import VideoUploader from '../../Components/VideoUploader/VideoUploader';
import './VideoGallery.css';
import { useDispatch, useSelector } from 'react-redux';
import { deleteVideo, fetchVideos, getUserData } from '../../Redux/actions/actions';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import imageNoVideo from '../../Images/no_data/no-video.png'
import { IoIosArrowDown } from 'react-icons/io';

const VideoGallery = ({ userLanguage, setLoading, storedUserId }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const videosData = useSelector((state) => state.videos);
  const { data: videoUrls } = videosData;
  const { uid } = userData;
  const { t } = useTranslation();
  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await dispatch(getUserData());
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, setLoading]);

  useEffect(() => {
    dispatch(fetchVideos(uid));
  }, [dispatch, uid]);

  const getFileNameFromURL = (url) => {
    const urlObj = new URL(url);
    const pathParts = urlObj.pathname.split('/');
    const encodedFileName = pathParts[pathParts?.length - 1];
    const decodedFileName = decodeURIComponent(encodedFileName);
    return decodedFileName;
  };

  const handleDeleteVideo = async (index) => {
    setLoading(true);

    try {
      const videoToDelete = videoUrls[index];
      const filePuth = getFileNameFromURL(videoToDelete);

      const result = await dispatch(deleteVideo(filePuth));

      if (result.success) {
        dispatch(fetchVideos(uid));
      } else {
        console.error(result.error);
      }
    } catch (error) {
      console.error('Error checking or deleting video:', error);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const isVideoGallery = window.location.pathname.includes('VideoGallery');

  const noPhotoArr = [
    imageNoVideo,
    imageNoVideo,
    imageNoVideo,
    imageNoVideo
  ]

  return (
    <>
      {isVideoGallery && (
        <div className='video-gallery-image-window'>
          {t('video_gallery')}
          <VideoUploader uid={uid} setLoading={setLoading} />
        </div>
      )}
      <div className='video-gallery-get-video'>
        <div className="video-gallery-image-container">
          {window.location.pathname.includes(`PersoneHistory`) && videoUrls?.length > 5 ? (
            videoUrls.slice(0, 6).map((url, index) => (
              <div key={index} className='video-container'>
                <video controls>
                  <source src={url} type="video/mp4" />
                </video>
              </div>
            ))
          ) : (
            videoUrls?.map((url, index) => (
              <div key={index} className='video-container'>
                <video controls>
                  <source src={url} type="video/mp4" />
                </video>
                {isVideoGallery && (

                  <div className='btn-remove'>
                    <div
                      className="delete-icon"

                    >
                      <RiDeleteBin6Line
                        size={20}
                        onClick={() => {
                          const confirmDelete = window.confirm(t('alert_are_you_sure_you_want_to_delete_this_video'));
                          if (confirmDelete) {
                            handleDeleteVideo(index);
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))
          )}


          {window.location.pathname.includes(`EnterWithQrCode`) && (
            <>
              {videoUrls?.map((url, index) => (
                <div key={index} className='video-container'>
                  <video controls>
                    <source src={url} type="video/mp4" />
                  </video>
                </div>
              ))}
            </>
          )}
        </div>

        {/* Проверяем если нет видео  */}
        {videoUrls?.length === 0 ?
          <div className='photo-gallery-get-photo' >
            <div className="photo-gallery-image-container">

              {noPhotoArr?.map((url, index) => (
                <div key={index} className='photo-gallery-image-window'>
                  <img src={url} alt={`Img-${index + 1}`} loading="eager" />
                </div>
              ))}

            </div>

          </div>
          :
          null
        }

        {window.location.pathname.includes('PersoneHistory') && (
          <>
            {videoUrls?.length === 0 ?

              <button
                className="package-button "

                onClick={() => navigate(`/${storedUserId}/VideoGallery`)}
                style={{ width: 294, height: 60 }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <IoIosArrowDown size={27} color='black' />
                  {t('add_video')}
                </div>
              </button>
              :
              <button
                className="package-button "

                onClick={() => navigate(`/${storedUserId}/VideoGallery`)}
                style={{ width: 294, height: 60 }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <IoIosArrowDown size={27} color='black' />
                  {t('show_all')}
                </div>
              </button>
            }
          </>
        )}
      </div>

      {isVideoGallery && <Footer userLanguage={userLanguage} />}
    </>
  );
};

export default VideoGallery;
