import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './PhoneInputField.css';

const PhoneInputField = ({ value, onChange, userLanguage }) => {
  return (
    <div 
    className={userLanguage?.includes("he") ? "input-phone-container-hebrew": "input-phone-container" } 
    dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
      <PhoneInput
        placeholder="Enter phone number"
        value={value}
        defaultCountry="IL"
        onChange={onChange}
        maxLength={17}
        className="phone-input"
      />
    </div>
  );
};

export default PhoneInputField;
