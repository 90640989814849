import React, { useState } from 'react';
import './ContactForm.css';
import { addMessage } from '../../Redux/actions/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './StyledInput.css';

const ContactForm = ({ userLanguage, setLoading, isMobile }) => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const the_name_is_required = t('the_name_is_required');
    const phone_is_required = t('phone_is_required');
    const alert_please_enter_a_valid_email_address = t('alert_please_enter_a_valid_email_address');
    const message_is_required = t('message_is_required');
    const your_message_has_been_sent_we_will_contact_you_shortly = t('your_message_has_been_sent_we_will_contact_you_shortly');

    const sanitizeInput = (value) => {
        return value.replace(/[^a-zA-Z0-9\s]/g, '');
    };

    const handleTextareaChange = (e) => {
        const value = e.target.value;
        setMessage(sanitizeInput(value));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name === "") {
            alert(the_name_is_required);
            return;
        }

        if (phone === "") {
            alert(phone_is_required);
            return;
        }

        if (email === "") {
            alert(alert_please_enter_a_valid_email_address);
            return;
        }

        if (message === "") {
            alert(message_is_required);
            return;
        }

        const sanitizedMessage = sanitizeInput(message);

        try {
            const result = await dispatch(addMessage(name, phone, email, sanitizedMessage));
            if (result && result.success === true) {
                alert(your_message_has_been_sent_we_will_contact_you_shortly);
                setName("");
                setPhone("");
                setEmail("");
                setMessage("");
            } else {
                console.error(result || "Unknown error");
            }
        } catch (error) {
            console.error(error);
            navigate('/Error');
        }
    };
    const currentUrl = window.location.href;
    const isContactPage = currentUrl.includes("ContactForm");

    return (
        <>
            {isContactPage && <div style={{marginTop: 70}}/>}
            <div className="contact-form" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
                {isContactPage && <h2>{t('contactUs')}</h2>}
                <form onSubmit={handleSubmit}>
                    <div className="input-container">
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            className="styled-input"
                            placeholder={t('full-name')}
                            pattern="[^<>]*"
                        />
                    </div>
                    <div className="input-container">
                        <input
                            required
                            type="number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="styled-input"
                            placeholder={t('phone')}
                            pattern="[^<>]*"
                        />
                    </div>
                    <div className="input-container" >
                        <input
                            required
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="styled-input"
                            placeholder={t('email')}
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        />
                    </div>
                    <div className="contact-input-group">
                        <textarea
                            required
                            value={message}
                            className={userLanguage?.includes("he") ? "textarea-input-hebrew" : "textarea-input"}
                            onChange={handleTextareaChange}
                            placeholder={t('your_message')}
                        />
                    </div>
                    <div className="home-image-button-container">
                        <button
                            type="submit"
                            className={userLanguage?.includes("he") ? "contact-button contact-button-hebrew" : "contact-button"}
                        >
                            {t('send')}
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default ContactForm;
