import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getUserData, updateUserData } from "../../Redux/actions/actions";

const AboutPersonComponent = ({ userLanguage, setLoading, isMobile }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const currentUser = useSelector((state) => state.userData);

    const { storedUserId } = location.state || {};

    useEffect(() => {
        setLoading(true);
        dispatch(getUserData());
        setLoading(false);
    }, [dispatch, setLoading]);

    const {
        birthDatePersone,
        deathDatePersone,
        firstNamePersone,
        lastNamePersone,
        // fathersNamePersons,
        storyPersone,
        memorialPlace,
        spouse,
        children,
        education,
        hobbies,
        placeOfBirth,
        uid
    } = currentUser;

    const [birthDatePersoneUpadte, setBirthDatePersoneUpadte] = useState(birthDatePersone || "1900-01-01");
    const [deathDatePersoneUpadte, setDeathDatePersoneUpadte] = useState(deathDatePersone);
    const [firstNamePersoneUpadte, setFirstNamePersoneUpadte] = useState(firstNamePersone);
    const [lastNamePersoneUpadte, setLastNamePersoneUpadte] = useState(lastNamePersone);
    // const [fathersNamePersonsUpdate, setFathersNamePersonsUpdate] = useState(fathersNamePersons);
    const [storyPersoneUpadte, setStoryPersoneUpadte] = useState(storyPersone);
    const [memorialPlaceUpadte, setMemorialPlaceUpadte] = useState(memorialPlace);
    const [spouseUpadte, setSpouseUpadte] = useState(spouse);
    const [childrenUpadte, setChildrenUpadte] = useState(children);
    const [educationUpadte, setEducationUpadte] = useState(education);
    const [hobbiesUpadte, setHobbiesUpadte] = useState(hobbies);
    const [placeOfBirthUpadte, setPlaceOfBirthUpadte] = useState(placeOfBirth);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
            const updatedData = {
                firstNamePersone: firstNamePersoneUpadte,
                lastNamePersone: lastNamePersoneUpadte,
                // fathersNamePersons: fathersNamePersonsUpdate,
                birthDatePersone: birthDatePersoneUpadte,
                deathDatePersone: deathDatePersoneUpadte,
                storyPersone: storyPersoneUpadte,
                placeOfBirth: placeOfBirthUpadte,
                memorialPlace: memorialPlaceUpadte,
                spouse: spouseUpadte,
                children: childrenUpadte,
                education: educationUpadte,
                hobbies: hobbiesUpadte,
            };

            await dispatch(updateUserData(uid, updatedData));
            navigate(`/${storedUserId}/PersoneHistory`);

        setLoading(false);
    }
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];
    
    return (
        <div className="signup-container" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>

            <div className="signup-title">

                <form className="signup-content signup-card-left" onSubmit={handleSubmit}>
                    <div className="signup-card">
                        <h2>{t('add_information_about_your_loved_one')}</h2>
                        <input
                            type="text"
                            value={firstNamePersoneUpadte !== null ? firstNamePersoneUpadte : ''}
                            onChange={(e) => setFirstNamePersoneUpadte(e.target.value)}
                            required
                            placeholder={t("name")}
                            pattern="[^<>]*"
                        />

                        <input
                            type="text"
                            value={lastNamePersoneUpadte !== null ? lastNamePersoneUpadte : ''}
                            onChange={(e) => setLastNamePersoneUpadte(e.target.value)}
                            required
                            placeholder={t("surname")}
                            pattern="[^<>]*"
                        />

                        {/* {userLanguage?.includes("ru") ?
                            <input
                                type="text"
                                value={fathersNamePersonsUpdate !== null ? fathersNamePersonsUpdate : ''}
                                onChange={(e) => setFathersNamePersonsUpdate(e.target.value)}
                                required
                                placeholder={t("fathersName")}
                                pattern="[^<>]*"
                            />
                            :
                            null} */}

                        <input
                            type="date"
                            value={birthDatePersoneUpadte !== null ? birthDatePersoneUpadte : formattedCurrentDate}
                            onChange={(e) => setBirthDatePersoneUpadte(e.target.value)}
                            required
                            placeholder={t("date_of_Birth")}
                            pattern="[^<>]*"

                        />

                        <input
                            type="date"
                            value={deathDatePersoneUpadte !== null ? deathDatePersoneUpadte : formattedCurrentDate}
                            onChange={(e) => setDeathDatePersoneUpadte(e.target.value)}
                            required
                            placeholder={t("date_of_departure")}
                            pattern="[^<>]*"

                        />

                        <textarea
                            type="textarea"
                            value={storyPersoneUpadte !== null ? storyPersoneUpadte : ''}
                            onChange={(e) => setStoryPersoneUpadte(e.target.value)}
                            required
                            placeholder={t("tell_us_about_the_person")}
                            className={userLanguage?.includes("he") ? "textarea-input-hebrew" : "textarea-input"}
                            style={{ width: isMobile ? "74%" : "77%" }}
                            pattern="[^<>]*"

                        />



                        <h2>{t('more_details')} </h2>
                        <input
                            type="text"
                            value={placeOfBirthUpadte !== null ? placeOfBirthUpadte : ''}
                            onChange={(e) => setPlaceOfBirthUpadte(e.target.value)}
                            placeholder={t("place_of_Birth")}
                            pattern="[^<>]*"

                        />

                        <input
                            type="text"
                            value={memorialPlaceUpadte !== null ? memorialPlaceUpadte : ''}
                            onChange={(e) => setMemorialPlaceUpadte(e.target.value)}
                            label="memorial_site"
                            placeholder={t("memorial_site")}
                            pattern="[^<>]*"

                        />

                        <input
                            type="text"
                            value={spouseUpadte !== null ? spouseUpadte : ''}
                            onChange={(e) => setSpouseUpadte(e.target.value)}
                            label="spouse"
                            placeholder={t("spouse")} ƒ
                            pattern="[^<>]*"

                        />
                        <input
                            type="text"
                            value={childrenUpadte !== null ? childrenUpadte : ''}
                            onChange={(e) => setChildrenUpadte(e.target.value)}
                            label="children"
                            placeholder={t("children")}
                            pattern="[^<>]*"

                        />
                        <input
                            type="text"
                            value={educationUpadte !== null ? educationUpadte : ''}
                            onChange={(e) => setEducationUpadte(e.target.value)}
                            label="education"
                            placeholder={t("education")}
                            pattern="[^<>]*"

                        />
                        <input
                            type="text"
                            value={hobbiesUpadte !== null ? hobbiesUpadte : ''}
                            onChange={(e) => setHobbiesUpadte(e.target.value)}
                            label="hobby"
                            placeholder={t("hobby")}
                            pattern="[^<>]*"

                        />
                        <br />

                        <button
                            className="package-button"
                            type="submit">
                            {t('continue')}
                        </button>

                    </div>
                </form>
            </div >
        </div >
    )
}

export default AboutPersonComponent;