import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app, db, updateEmail, updatePassword } from "../../Components/Firebase/Firebase";
import { doc, setDoc } from "firebase/firestore";
import { updateUserData } from "../../Redux/actions/actions";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const ChangeAccount = ({ userLanguage, setLoading }) => {
    const location = useLocation();
    const { email, password } = location.state || {};
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('+972');
    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [registrationError, setRegistrationError] = useState('');
    const { t } = useTranslation();
    const alertPleaseEnterYourName = t('alert_please_enter_your_name');
    const alert_please_enter_a_valid_email_address = t('alert_please_enter_a_valid_email_address');
    const alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number = t('alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleUpdateEmailAndPassword = async (e) => {
        e.preventDefault();
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
        const passwordRegex = /^(?=.*\d)[\s\S]{6,}$/;
        setLoading(true);
        if (!fullName) {
            setRegistrationError(alertPleaseEnterYourName);
            setLoading(false);
            return;
        } else if (!emailRegex.test(email || newEmail)) {
            setRegistrationError(alert_please_enter_a_valid_email_address);
            setLoading(false);
            return;
        } else if (!passwordRegex.test(password || newPassword || confirmNewPassword)) {
            setRegistrationError(alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number);
            setLoading(false);
            return;
        } else if (!phone) {
            setRegistrationError(t('alert_please_enter_your_phone'));
            setLoading(false);
            return;
        } else {
            const auth = getAuth(app);
            try {
                await signInWithEmailAndPassword(auth, email, password);
                const user = auth.currentUser;
                const otherCollectionDocRef = doc(db, "new-users", user.uid);
                await setDoc(otherCollectionDocRef, {
                    uid: user.uid,
                    price: "500",
                    country: "Israel",
                    email: newEmail,
                    fullName,
                    phone,
                    dateOfCreation: new Date().toISOString(),
                });
                await updateEmail(user, newEmail);
                await updatePassword(user, newPassword);
                const updatedData = {
                    phone: phone,
                    fullName: fullName,
                    email: newEmail,
                    isUserRegistered: true
                };
                await dispatch(updateUserData(user.uid, updatedData));
                const userid = user.uid.substring(0, 10);
                localStorage.setItem('userId', userid);
                navigate(`/${userid}/PersoneHistory`);
            } catch (error) {
                alert('Error updating email and password:', error.message);
            }
        }
        setLoading(false);
    };

    return (
        <div className="sign-in-form" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
            <div className='sign-In-titel'>
                <div className="temp-sign-title">
                    <h4 style={{ margin: 'auto' }}>{t('enter_your_personal_email')}</h4>
                </div>
                <form className="sign-in-input-container" onSubmit={handleUpdateEmailAndPassword} style={{marginTop: 50}}>
                    <input
                        type="text"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                        placeholder={t('full-name')}
                        className="styled-input"
                        style={{ color: "#013B46", borderColor: "#013B46", marginBottom: 10, width: 250 }}

                    />
                    <input
                        type="email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        placeholder={t('new_email')}
                        className="styled-input"
                        style={{ color: "#013B46", borderColor: "#013B46", marginBottom: 10, width: 250 }}

                    />
                    <label style={{ position: 'relative' }}>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder={t('new_password')}
                            className="styled-input"
                            style={{ color: "#013B46", borderColor: "#013B46", marginBottom: 10, width: 250 }}

                        />
                    </label>
                    <label style={{ position: 'relative' }}>
                        <input
                            type="password"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            placeholder={t('confirm_your_new_password')}
                            className="styled-input"
                            style={{ color: "#013B46", borderColor: "#013B46", marginBottom: 10, width: 250 }}

                        />
                    </label>
                    <input
                        type="tel"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="+972"
                        className="styled-input"
                        style={{ color: "#013B46", borderColor: "#013B46", marginBottom: 10, width: 250 }}

                    />
                    {registrationError && (
                        <p style={{ color: 'red', textAlign: 'center' }}>{registrationError}</p>
                    )}
                    <div style={{ marginTop: '20px' }}>
                        <button
                            type="submit"
                            className="package-button"
                            style={{ color: "#013B46", borderColor: "#013B46", width: 200 }}
                        >
                            {t('next')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChangeAccount;
