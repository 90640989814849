const initialState = {
    usersData: [],
    userData: {},
    isLoggedIn: false,
    error: null,
    photoUrls: [],
    videos: [],
    discounts: [],
    uploading: false,
    saveDataNewUser: {}
};

const reducer = (state = initialState, action) => {
    // console.log(state,'state');
    switch (action.type) {
        case 'UPDATE_USER_DATA':
            return {
                ...state,
                userData: action.payload,
                isLoggedIn: action.isLoggedIn,
            };
        case 'LOGOUT_USER':
            return { ...state, userData: {}, isLoggedIn: false };

        case 'UPLOAD_PHOTOS_SUCCESS':
            return { ...state, photoUrls: [...state.photoUrls, ...action.payload.uploadedPhotoUrls], };

        case 'UPLOAD_PHOTOS_FAILURE':
            // Обработка ошибки при загрузке фотографий, если необходимо
            return state;

        case 'SET_PHOTO_URLS':
            return { ...state, photoUrls: action.payload.photoUrls, };

        case "DELETE_PHOTO_REQUEST":
            return { ...state, deletePhotoLoading: true, deletePhotoError: null };

        case "ADD_VIDEO_REQUEST":
            return { ...state, loading: true, error: null };
        case " ADD_VIDEO_SUCCESS":
            return {
                ...state, videos: [...state.videos, ...action.payload],
            };
        case "ADD_VIDEO_FAILURE":
            return { ...state, error: action.payload };

        case "DELETE_VIDEO_SUCCESS":
            return {
                ...state,
                loading: false,
                videos: state.videos.data.filter((video) => video !== action.payload),
                error: null
            };
        case "DELETE_VIDEO_FAILURE":
            return { ...state, loading: false, error: action.payload };

        case "FETCH_VIDEOS_REQUEST":
            return {
                ...state, videos: { ...state.videos, loading: true, error: null, },
            };

        case "FETCH_VIDEOS_SUCCESS":
            return {
                ...state, videos: { ...state.videos, data: action.payload, loading: false, },
            };

        case "FETCH_VIDEOS_FAILURE":
            return {
                ...state, videos: {
                    ...state.videos, loading: false, error: action.payload,
                },
            };

        case 'UPDATE_VIDEO_LIST':
            return {
                ...state,
                data: action.payload,
            };

        case 'GET_ALL_DISCOUNTS_SUCCESS':
            return {
                ...state,
                allDiscounts: action.payload,
                error: null,
            };
        case 'GET_ALL_DISCOUNTS_FAILURE':
            return {
                ...state,
                allDiscounts: [],
                error: action.payload,
            };
        case 'ADD_PDF_REQUEST':
            return {
                ...state,
                uploading: true,
                error: null
            };
        case 'ADD_PDF_SUCCESS':
            return {
                ...state,
                uploading: false
            };
        case 'ADD_PDF_FAILURE':
            return {
                ...state,
                uploading: false,
                error: action.payload
            };

        case 'SAVE_NEW_USER_DATA':
            return {
                ...state,
                saveDataNewUser: { ...state.videos, data: action.payload },
            };

        default:
            return state;
    }
};

export default reducer;
