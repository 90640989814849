import React, { useEffect, useState } from 'react';
// import input from '../../Components/input/input';
// import button from '../../Components/button/button';
import { useDispatch, useSelector } from 'react-redux';
import { addFreeText, getUserData } from '../../Redux/actions/actions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './FreeTextSection.css';
import Spinner from '../../Components/Spinner/Spinner-old';

const FreeTextSection = ({ storedUserId, setShowFreeTextForm, userLanguage, setModalIsOpen }) => {

    console.log(userLanguage, 'userLanguage');

    const [loading, setLoading] = useState(false);
    const [freeText, setFreeText] = useState('');
    const [authorOfText, setAuthorOfText] = useState('');
    const [modalScrollPosition, setModalScrollPosition] = useState(0);
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(false); // Стейт для определения мобильного устройства

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentUser = useSelector((state) => state.userData);

    useEffect(() => {
        setLoading(true);
        dispatch(getUserData());
        setLoading(false);
    }, [dispatch, setLoading]);

    const {
        uid
    } = currentUser;

    const getStoredUserId = uid?.includes(storedUserId);


    const handleFreeTextSave = async () => {
        setLoading(true);

        if (getStoredUserId) {
            const result = await dispatch(addFreeText(uid, freeText, authorOfText));

            if (result.success) {
                setFreeText('');
                setAuthorOfText('');
                setShowFreeTextForm(false);

                // После успешного добавления комментария вызываем функцию обновления данных пользователя
                dispatch(getUserData(getStoredUserId));
            } else {
                // Handle error actions here
                console.error(result.error);
                navigate('/Error');
                setLoading(false);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        const handleModalScroll = () => {
            setModalScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', handleModalScroll);

        return () => {
            window.removeEventListener('scroll', handleModalScroll);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Проверяем ширину экрана и устанавливаем isMobile в true, если ширина меньше 768px
        };

        handleResize(); // Вызываем функцию сразу, чтобы установить начальное значение

        window.addEventListener('resize', handleResize); // Слушаем изменения размера экрана

        return () => window.removeEventListener('resize', handleResize); // Убираем слушатель при размонтировании компонента
    }, []);


    return (
        <>
            {loading ?
                <Spinner loading={loading} />
                :
                <div className='free-text-container' dir={userLanguage?.includes("he") ? "rtl" : "ltr"} style={{ top: `${modalScrollPosition}px` }}>
                    <input
                        type="text"
                        value={authorOfText}
                        onChange={(e) => setAuthorOfText(e.target.value)}
                        placeholder={t('author')}
                        style={{ height: 40, marginBottom: 20, minWidth: isMobile ? 265 : '90%', }}
                        className="styled-input"

                    />
                    <textarea
                        type="textarea"
                        value={freeText}
                        onChange={(e) => setFreeText(e.target.value)}
                        style={{ height: 150, minWidth: isMobile ? 265 : '90%', }}

                        placeholder={t('comment')}
                        className="styled-input"

                    />
                    <div className='free-text-buttons'>
                        {freeText !== "" && authorOfText !== "" ?
                            <button
                                buttonText="save"
                                onClick={() => handleFreeTextSave({ moreBiography: true })}
                                style={{ marginLeft: 10, marginTop: 10, borderStyle: 'solid', borderWidth: 1, borderColor: 'white' }}
                                className="package-button"

                            >
                                {t('save')}
                            </button>
                            : null}
                        <button
                            buttonText="cancel"
                            onClick={() => { setShowFreeTextForm(false);  }}
                            style={{ marginLeft: 10, marginTop: 10, borderStyle: 'solid', borderWidth: 1, borderColor: 'white' }}
                            className="package-button"

                        >
                            {t('cancel')}
                        </button>
                    </div>
                </div>
            }
        </>
    );
};

export default FreeTextSection;
