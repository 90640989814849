import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import './SignInForm.css';
import { logInWithEmailAndPassword } from "../../Components/Firebase/Firebase";
import { getUserData } from "../../Redux/actions/actions";
import { useTranslation } from "react-i18next";
import { getDoc } from "firebase/firestore";

const SignInForm = ({ userLanguage, setLoading }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        dispatch(getUserData());
        setLoading(false);
    }, [dispatch, setLoading]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (email !== "" && password !== "") {
            try {
                await logInWithEmailAndPassword(email, password, navigate, dispatch, t, getDoc);
            } catch (err) {
                alert(t('invalid_username_password'));
                setLoading(false);
            }
        }
        setLoading(false);
    }

    return (
        <>
            <div className="sign-in-form" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
                <div className='sign-In-titel'>
                    <div className="sign-in-input-container">
                        <h4>{t('login_to_your_account_or_register')}</h4>
                        <form onSubmit={handleLogin} className="sign-in-form-vertical">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                placeholder={t('email')}
                                style={{ color: "#013B46", borderColor: "#013B46", marginBottom: 10, width: 250 }}
                                className="styled-input"
                            />
                            <label style={{ position: 'relative' }}>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder={t('password')}
                                    required
                                    pattern="[A-Za-z0-9\s]+"
                                    style={{ color: "#013B46", borderColor: "#013B46", width: 250 }}
                                    className="styled-input"
                                />
                            </label>
                            <div className="sign-in-forgot-password-container">
                                <div className="sign-in-forgot-password-link">
                                    <Link to="/Forgot-password">
                                        {t('forgot_your_password')}
                                    </Link>
                                </div>
                                <div className="sign-in-forgot-password-link">
                                    <Link to="/PackagesOffer">
                                        {t('not_registered_yet')}
                                    </Link>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="package-button"
                                style={{ color: "#013B46", backdropFilter: "white" }}
                            >
                                {t('signIn')}
                            </button>
                        </form>
                    </div>
                </div>
            </div >
        </>
    );
};

export default SignInForm;
