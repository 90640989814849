import React, { useEffect, useState } from 'react';
import logo1 from '../../Images/logos/logo_transparant_he.png';
import logo2 from '../../Images/logos/logo_transparant_ru.png';
import logo3 from '../../Images/logos/logo_transparant_en.png';
import './Spinner.css';

const images = [logo1, logo2, logo3];

const Spinner = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 4000); // Меняем изображение каждые 4 секунды (чтобы дать время на анимацию)

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="spinner">
            <img src={images[currentImageIndex]} alt="Logo" className="spinner-image" />
        </div>
    );
};

export default Spinner;
