import QRCode from 'qrcode';
import { getStorage, ref, uploadString } from 'firebase/storage';

export const generateQRCode = async ({ userId, navigate, isCreateUser }) => {
    const color = "#000";
    const companyName = 'QrMoment';
    const shortenedUserId = userId?.substring(0, 10);

    try {
        const updatedUrl = `http://qrmoment.co.il/${shortenedUserId}/EnterWithQrCode`;

        // Создаем QR-код
        const qrCanvas = document.createElement('canvas');
        const qrCtx = qrCanvas.getContext('2d');
        await QRCode.toCanvas(qrCanvas, updatedUrl, {
            width: 2500,
            margin: 1,
            color: {
                dark: color,
            },
        });

        // Создаем canvas для текста
        const textCanvas = document.createElement('canvas');
        const textCtx = textCanvas.getContext('2d');
        textCanvas.width = qrCanvas.width;
        textCanvas.height = qrCanvas.height;

        // Рисуем белый прямоугольник в центре
        const centerX = qrCanvas.width / 2;
        const centerY = qrCanvas.height / 2;
        const textWidth = textCtx.measureText(companyName).width;
        const textHeight = 180; // Высота текста

        const rectangleWidth = textWidth + 1200; // Увеличиваем ширину прямоугольника на 20 пикселей

        textCtx.fillStyle = 'white';
        textCtx.fillRect(centerX - rectangleWidth / 2, centerY - textHeight / 2, rectangleWidth, textHeight);

        // Задаем параметры для названия компании
        textCtx.fillStyle = color;
        textCtx.font = 'bold 180px Arial';
        textCtx.textAlign = 'center';
        textCtx.textBaseline = 'middle'; // Выравниваем текст по центру

        textCtx.fillText(companyName, centerX, centerY);

        // Объединяем QR-код и текст
        qrCtx.drawImage(textCanvas, 0, 0);

        // Рисуем номер компании в верхнем левом углу
        // const topY = 30; // Отступ от верхнего края
        // const topX = 100; // Отступ от левого края

        // qrCtx.fillStyle = 'black';
        // qrCtx.font = 'bold 12px Arial';
        // qrCtx.textAlign = 'right';
        // qrCtx.fillText(shortenedUserId, topX, topY);
        const bottomY = qrCanvas.height - 30; // Offset from the bottom edge
        const bottomX = qrCanvas.width / 2; // Center align horizontally

        qrCtx.fillStyle = 'black';
        qrCtx.font = 'bold 15px Arial';
        qrCtx.textAlign = 'center';
        qrCtx.fillText(shortenedUserId, bottomX, bottomY);
        // Загрузка изображения в Firebase Storage
        const storage = getStorage();
        const storageRef = ref(storage, `${userId}/QrCode/${userId}`);
        await uploadString(storageRef, qrCanvas.toDataURL('image/png'), 'data_url');

        if (isCreateUser) {
            alert("User created successful")
            // navigate('CreateDefaultUser')
        }
        else {
            navigate(`/${shortenedUserId}/AboutPersonComponent`);
        }

        // console.log(`QR Code for ${updatedUrl} created and saved as ${userId}`);
    } catch (err) {
        console.error('Error creating QR Code:', err);
    } finally {
    }
};
