import React, { useEffect, useState } from 'react';
import './App.css';
import Navigation from './Navigation/Navigation';
import Modal from 'react-modal';
import { BrowserRouter as Router } from 'react-router-dom';
import CookieConsent from './Components/CookieConsent/CookieConsent';
// import Heshbonit from './Components/Receipt/Heshbonit';

function App() {
  const [showCookieConsent, setShowCookieConsent] = useState(true);

  useEffect(() => {
    // Устанавливаем корневой элемент для модальных окон
    Modal.setAppElement('#root');

    // Проверяем локальное хранилище на наличие согласия на использование cookie
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'true') {
      setShowCookieConsent(false);
    }
  }, []);

  const handleCookieConsent = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowCookieConsent(false); // После получения согласия скрываем компонент
  };

  return (
    <div className="App">
      <Router>
        <Navigation />
      </Router>

      {/* <Heshbonit /> */}



      {showCookieConsent && <CookieConsent handleCookieConsent={handleCookieConsent} />}
    </div>
  );
}

export default App;
