import React, { useEffect } from 'react';
import './PageNoFound.css'
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const PageNoFound = ({ loadingUserData, setLoading, userLanguage }) => {
  const { t } = useTranslation();

  // const getEnterUser = window.location.pathname.includes("EnterWithQrCode");
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true)
    if (loadingUserData) {
      setLoading(false)
    }
    setLoading(false)

  }, [loadingUserData, setLoading])

  return (
    <>
      <div className='page-not-found-details'>
        <h1>404</h1>
        <h2>{t('sorry_page_not_found')}</h2>
        <p>{t('the_page_you_are_looking_for_might_have_been_removed_had_its_name_changed')}</p>
        <div style={{ textAlign: 'center' }}>
          <button
            onClick={() => navigate('/')}
            className={userLanguage?.includes("he") ? "contact-button contact-button-hebrew" : "contact-button"}
          >
            {t('home_page')}
          </button>
        </div>
      </div>
      <Footer />

    </>
  );
};

export default PageNoFound;
