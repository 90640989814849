import React from 'react';
import './PrivacyPolicy.css';
import Footer from '../../Screens/Footer/Footer';

const PrivacyPolicyIsr = ({ userLanguage }) => {

    return (
        <div style={{ backgroundColor: '#003B46' }}>
            <div className="privacy-policy-container" style={{ direction: 'rtl' }}>
                <h1 className="policy-heading">מדיניות פרטיות</h1>
                <div className="policy-content">
                    <p>
                        מאחר עסק פטור מיכאל מלז'יק מכבד את פרטיות המשתמשים באתרי האינטרנט שהיא מנהלת ומפעילה
                        (https://qrmoment.co.il) החליטה החברה לפרסם את מדיניותה ביחס להגנת הפרטיות באתרים, והיא מתחייבת כלפי המשתמש לקיים מדיניות זו.
                        מטרת המדיניות להסביר מהם נוהגי החברה ביחס לפרטיות המשתמשים באתרים, וכיצד משתמשת החברה במידע, הנמסר לה על-ידי המשתמשים באתרים או הנאסף על-ידה בעת השימוש באתרים.

                    </p>
                </div>
                <div className="policy-content">
                    <p>
                        <strong>כללי</strong>
                    </p>
                    <p>
                        בעת שימוש בשירותי האתרים נאסף מידע עליך. חלק מהמידע מזהה אותך באופן אישי, כלומר בשמו ובכתובתך, מוצרים ושירותים שרכשת או ביקשת למכור, אמצעי התשלום ששימשו אותך, וכיו"ב. זהו המידע שאתה מוסר ביודעין, לדוגמה בעת שתירשם לשירותים באתרים. חלק מהמידע אינו מזהה אותך אישית ואינו נשמר ביחד עם פרטיך. זהו מידע סטטיסטי ומצטבר. לדוגמה, פרסומות שקראת באתר, העמודים שבהם צפית, ההצעות והשירותים שעניינו אותך, כתובת האינטרנט (IP ) שממנה פנית  ועוד.
                    </p>

                </div>
                <div className="policy-content">
                    <p>
                        <strong>רישום לשירותים </strong>
                    </p>
                    <p>
                        ככל שנדרשים פרטים אישיים בעת רישום לשירותים באתרים או בעת רכישת מוצרים בהם, החברה תבקש ממך רק את המידע הנחוץ במישרין לאספקת השירותים או לרכישת המוצרים.                    </p>
                </div>
                <div className="policy-content">
                    <p>
                        <strong>מאגר המידע</strong>
                    </p>
                    <p>
                        הנתונים שנאספו  יישמרו במאגר המידע של החברה ובאחריותה.
                    </p>
                </div>
                <div className="policy-content">
                    <p>
                        <strong>השימוש במידע</strong>
                    </p>
                    <p>
                        השימוש במידע שנאסף, , ייעשה רק על פי מדיניות פרטיות זו או על פי הוראות כל דין, על מנת –
                    </p>
                    <p>
                        לאפשר להשתמש בשירותים שונים באתרים .
                    </p>
                    <p>
                        לשפר ולהעשיר את השירותים והתכנים המוצעים באתרים.
                    </p>
                    <p>
                        לשנות או לבטל שירותים ותכנים קיימים.
                    </p>
                    <p>
                        לצורך רכישת מוצרים ושירותים באתרים – לרבות פרסום מידע ותכנים.
                    </p>
                    <p>
                        כדי להתאים את המודעות שיוצגו בעת הביקור באתרים לתחומי ההתעניינות שלך
                        המידע שישמש את החברה יהיה בעיקרו מידע סטטיסטי, שאינו מזהה אישית.
                    </p>
                </div>
                <div className="policy-content">
                    <p>
                        <strong>דיוור ישיר אלקטרוני</strong>
                    </p>
                    <p>
                        החברה  מעונינת לשלוח אליך מדי פעם בדואר אלקטרוני מידע בדבר שירותיה וכן מידע שיווקי ופרסומי
                    </p>
                    <p>
                        מידע כזה ישוגר אליך רק אם נתת הסכמה מפורשת לכך, ובכל עת תוכל לבטל את הסכמתך ולחדול מקבלתו.
                    </p>
                    <p>
                        החברה לא תמסור את פרטיך האישיים למפרסמים. עם זאת, היא רשאית להעביר מידע סטטיסטי על פעילות המשתמשים באתרים.
                    </p>
                    <p>
                        מידע סטטיסטי שיועבר לא יזהה אותך אישית.
                    </p>
                </div>

                <div className="policy-content">
                    <p>
                        <strong>מסירת מידע לצד שלישי</strong>
                    </p>
                    <p>
                        החברה לא תעביר לצדדים שלישיים את פרטיך האישיים והמידע שנאסף על פעילותך באתר אלא במקרים המפורטים להלן:
                    </p>
                    <p>
                        אם תרכוש מוצרים ושירותים מצדדים שלישיים המציעים אותם למכירה באמצעות האתרים, יועבר לצדדים שלישיים אלה המידע הדרוש להם לשם השלמת תהליך הרכישה
                    </p>
                    <p>
                        במקרה של מחלוקת משפטית בינך לבין החברה שתחייב חשיפת פרטיך;.
                    </p>
                    <p>
                        אם תבצע באתרים, פעולות שבניגוד לדין;
                    </p>
                    <p>
                        אם יתקבל צו שיפוטי המורה למסור את פרטיך או המידע אודותיך לצד שלישי;
                    </p>
                    <p>
                        אם החברה תמכור או תעביר בכל צורה שהיא את פעילות האתרים לתאגיד כלשהו – וכן במקרה שתתמזג עם גוף אחר או תמזג את פעילות האתרים עם פעילותו של צד שלישי, ובלבד שתאגיד זה יקבל על עצמו כלפיך את הוראות מדיניות פרטיות זו.
                    </p>
                </div>

                <div className="policy-content">
                    <p>
                        <strong>Cookies</strong>
                    </p>
                    <p>
                        אתר החברה  משתמש ב"עוגיות" (Cookies) לצורך תפעולם השוטף והתקין, ובכלל זה כדי לאסוף נתונים סטטיסטיים אודות השימוש באתר, לאימות פרטים, כדי להתאים את האתר להעדפותיך האישיות ולצורכי אבטחת מידע.
                    </p>
                    <p>
                        דפדפנים מודרניים כוללים אפשרות להימנע מקבלת Cookies. אם אינך יודע כיצד לעשות זאת, בדוק בקובץ העזרה של הדפדפן שבו אתה משתמש.
                    </p>
                </div>
                <div className="policy-content">
                    <p>
                        <strong>פרסומות של צדדים שלישיים</strong>
                    </p>
                    <p>
                        החברה מתירה לחברות אחרות לנהל את מערך הפרסומות באתרים. המודעות שבהן אתה צופה בעת הביקור באתרי מגיעות ממחשביהן של אותן חברות. כדי לנהל את הפרסומות שלהן, חברות אלה חברות אלה מציבות Cookies במחשבך. ה-Cookies מאפשרים להן לאסוף מידע על האתרים שבהם צפית בפרסומות שהציבו ועל אילו פרסומות הקשת. השימוש שחברות אלה עושות ב-Cookies כפוף למדיניות הפרטיות שלהן ולא למדיניות הפרטיות של החברה
                    </p>
                </div>
                <div className="policy-content">
                    <p>
                        <strong>אבטחת מידע</strong>
                    </p>
                    <p>
                        החברה מיישמת באתריה מערכות ונהלים עדכניים לאבטחת מידע. בעוד שמערכות ונהלים אלה מצמצמים את הסיכונים לחדירה בלתי-מורשית, אין הם מעניקים בטחון מוחלט. לכן, החברה לא מתחייבת ששירותיה  יהיו חסינים באופן מוחלט מפני גישה בלתי-מורשית למידע המאוחסן בהם.
                    </p>
                </div>
                <div className="policy-content">
                    <p>
                        <strong> זכות לעיין במידע</strong>
                    </p>
                    <p>
                        על-פי חוק הגנת הפרטיות, התשמ"א – 1981, כל אדם זכאי לעיין במידע שעליו המוחזק במאגר מידע. אדם שעיין במידע שעליו ומצא כי אינו נכון, שלם, ברור או מעודכן, רשאי לפנות לבעל מאגר המידע בבקשה לתקן את המידע או למוחקו.
                    </p>
                    <p>
                        פנייה כזאת יש להפנות info@qrmoment.com.
                    </p>
                    <p>
                        בנוסף, אם המידע שבמאגרי החברה משמש לצורך פניה אישית אליך,  אתה זכאי על-פי חוק הגנת הפרטיות, התשמ"א- 1981 לדרוש בכתב שהמידע המתייחס אליך יימחק ממאגר המידע
                    </p>
                    <p>
                        שינויים במדיניות הפרטיות
                    </p>
                    <p>
                        בכל מקרה בו יבוצעו במדיניות זו שינויים מהותיים בהוראות שעניינן השימוש במידע אישי שמסרת, תפורסם על-כך הודעה בעמוד הבית של האתרים.
                    </p>
                </div>
            </div>
            <Footer userLanguage={userLanguage} />
        </div>
    );
}

export default PrivacyPolicyIsr;
