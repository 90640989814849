import React, { useState } from 'react';
import Slider from 'react-slick';
import './FAQItem.css';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FAQItem = ({ faq, index, toggleFAQ, userLanguage }) => {
  const { question, answer } = faq;
  const { t } = useTranslation();

  return (
    <div
      className={`faq-item ${faq.open ? 'open' : ''}`}
      onClick={() => toggleFAQ(index)}
      dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
      <div className="faq-question">
        <h4>{t(question)}</h4>
      </div>
      <div className="faq-answer">
        <p>{t(answer)}</p>
      </div>
    </div>
  );
};

const FAQSection = ({ userLanguage }) => {
  const faqs = [
    { question: 'question_number_one', answer: 'answer_number_one' },
    { question: 'question_number_two', answer: 'answer_number_two' },
    { question: 'question_number_three', answer: 'answer_number_three' },
    { question: 'question_number_four', answer: 'answer_number_four' },
    { question: 'question_number_five', answer: 'answer_number_five' },
    { question: 'question_number_six', answer: 'answer_number_six' },
    { question: 'question_number_seven', answer: 'answer_number_seven' },
    { question: 'question_number_eight', answer: 'question_number_eight' },
    { question: 'question_number_nine', answer: 'answer_number_nine' },
    { question: 'question_number_ten', answer: 'answer_number_ten' },
    { question: 'question_number_eleven', answer: 'answer_number_eleven' },
    { question: 'question_number_twelve', answer: 'answer_number_twelve' },
    { question: 'question_number_thirteen', answer: 'answer_number_thirteen' }
  ];

  const [faqsState, setFaqsState] = useState(faqs);

  const toggleFAQ = index => {
    setFaqsState(faqsState.map((faq, i) => {
      if (i === index) {
        faq.open = !faq.open;
      } else {
        faq.open = false;
      }
      return faq;
    }));
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: i => (
      <div className="faq-item-dot-item"></div>
    )
  };

  const chunkArray = (myArray, chunk_size) => {
    const results = [];
    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }
    return results;
  };

  const chunks = chunkArray([...faqsState], 3);

  const isAnyFAQOpen = faqsState.some(faq => faq.open);

  return (
    <div className={`faq-section ${isAnyFAQOpen ? 'dots-hidden' : ''}`}>
      <Slider {...settings}>
        {chunks.map((chunk, index) => (
          <div key={index} className="faq-slide">
            {chunk.map((faq, faqIndex) => (
              <FAQItem key={faqIndex} index={faqIndex + index * 3} faq={faq} toggleFAQ={toggleFAQ} userLanguage={userLanguage} />
            ))}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default FAQSection;
