import React from 'react';
import Select from 'react-select';
import i18nIsoCountries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import ruLocale from 'i18n-iso-countries/langs/ru.json';
import heLocale from 'i18n-iso-countries/langs/he.json';
import './CountrySelector.css'; // Подключите ваш файл стилей

i18nIsoCountries.registerLocale(enLocale);
i18nIsoCountries.registerLocale(ruLocale);
i18nIsoCountries.registerLocale(heLocale);

const CountrySelector = ({ userLanguage, country, setCountry }) => {

    const getCountries = (lang) => {
        const countries = i18nIsoCountries.getNames(lang);
        return Object.entries(countries).map(([key, value]) => ({
            value: key,
            label: value,
        }));
    };

    const languageMap = {
        en: 'en',
        ru: 'ru',
        he: 'he',
    };

    const countryOptions = getCountries(languageMap[userLanguage] || 'en');

    // Styles for Select input (countries)
    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: 50, // Set height
            width: '100%', // Full width inside the container
            textAlign: userLanguage?.includes("he") ? 'right' : 'left',
            paddingLeft: 15,
            borderWidth: 1,
            borderColor: "#4ae9e4",
            borderStyle: 'solid',
            marginBottom: 10
        }),
        menu: (provided) => ({
            ...provided,
            width: '100%',
        }),
    };

    const containerClass = userLanguage?.includes("he")
        ? 'country-selector-container-hebrew'
        : 'country-selector-container';

    return (
        <div className={containerClass}>
            <div className="country-selector-input">
                <Select
                    options={countryOptions}
                    value={countryOptions.find(option => option.value === country)}
                    onChange={(selectedOption) => setCountry(selectedOption.value)}
                    placeholder="Select your country"
                    styles={customStyles}
                />
            </div>
        </div>
    );
};

export default CountrySelector;
