import React, { useState, useEffect } from 'react';
import PhotoUploader from '../../Components/PhotoUploader/PhotoUploader';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useDispatch, useSelector } from 'react-redux';
import { deletePhoto, getPhotoUrls } from '../../Redux/actions/actions';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosArrowDown, IoIosCloseCircleOutline } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import imageNoPhoto from '../../Images/no_data/no-image.png';
import './PhotoGallery.css';

const PhotoGallery = ({ userLanguage, setLoading, storedUserId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const photoUrls = useSelector((state) => state.photoUrls);
  const userData = useSelector((state) => state.userData);
  const { uid } = userData;
  const navigate = useNavigate();

  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [showGallery, setShowGallery] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getPhotoUrls(uid)).finally(() => setLoading(false));
    setLoading(false);

  }, [dispatch, uid, setLoading]);

  const handleDeletePhoto = async (url) => {
    setLoading(true);
    try {
      await dispatch(deletePhoto(uid, url));
      await dispatch(getPhotoUrls(uid));
      setShowGallery(false); // Закрываем галерею после удаления фото
    } catch (error) {
      console.error('Error deleting photo:', error);
    } finally {
      setLoading(false);
    }
  };

  const images = photoUrls.map(url => ({
    original: url,
    thumbnail: url,
    originalAlt: 'Photo',
    thumbnailAlt: 'Thumbnail'
  }));

  const noPhotoArr = Array.from({ length: 4 }, () => imageNoPhoto);

  return (
    <div className='photo-gallery-container'>
      {window.location.pathname.includes('PhotoGallery') && (
        <div className='photo-gallery-image-window'>
          <p >{t('photo_gallery')}</p>
          <PhotoUploader userLanguage={userLanguage} setLoading={setLoading} />
        </div>
      )}
      <div className='photo-gallery-get-photo'>

        <div className="photo-gallery-image-container">
          {photoUrls.length > 0 ? (
            photoUrls.map((url, index) => (
              <div key={index} className='photo-gallery-image-window'>
                <img
                  src={url}
                  alt={`Img-${index + 1}`}
                  onClick={() => {
                    setSelectedPhotoIndex(index);
                    setShowGallery(true);
                  }}
                  loading="eager"
                />
                {/* {window.location.pathname.includes('PhotoGallery') && (
                  <div className='btn-remove'>
                    <RiDeleteBin6Line
                      color="#003B46"
                      onClick={() => {
                        const confirmDelete = window.confirm(t('alert_are_you_sure_you_want_to_delete_this_photo'));
                        if (confirmDelete) {
                          handleDeletePhoto(url);
                        }
                      }}
                    />
                  </div>
                )} */}
              </div>
            ))
          ) : (
            <div className="photo-gallery-image-container">
              {noPhotoArr.map((url, index) => (
                <div key={index}>
                  <img src={url} alt={`Img-${index + 1}`} loading="eager" />
                </div>
              ))}
            </div>
          )}
        </div>

        {window.location.pathname.includes('PersoneHistory') && (
          <button
            className="package-button "
            onClick={() => navigate(`/${storedUserId}/PhotoGallery`)}
            style={{ width: 294, height: 60, }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              {/* <img src={vector} alt="vector" style={{ width: 10, height: 10, marginTop: 5 }} /> */}
              <IoIosArrowDown size={27} color='black' />


              {photoUrls.length > 0 ? t('show_all') : t('add_photo')}
            </div>
          </button>
        )}
      </div>


      {showGallery && (
        <div className="photo-gallery-modal">
          <div className="photo-gallery-modal-close-button" onClick={() => setShowGallery(false)}>
            <IoIosCloseCircleOutline size={50} color='white' />
          </div>
          <div
            className="photo-gallery-modal-delete-button"
            style={userLanguage?.includes('he') ? { right: 10 } : { left: 10 }}
          >
            <RiDeleteBin6Line
              size={50}
              className="delete-button"
              color='white'
              onClick={() => {
                const confirmDelete = window.confirm(t('alert_are_you_sure_you_want_to_delete_this_photo'));
                if (confirmDelete) {
                  handleDeletePhoto(images[selectedPhotoIndex].original);
                }
              }}
            />
          </div>
          <ImageGallery
            items={images}
            showThumbnails={false}
            showFullscreenButton={true}
            showPlayButton={false}
            startIndex={selectedPhotoIndex}
            onSlide={(index) => setSelectedPhotoIndex(index)}
            onClose={() => setShowGallery(false)}
            renderCustomClose={() => null} // Мы будем использовать свою кнопку закрытия
            autoPlay={true}
          />
        </div>

      )
      }
      {window.location.pathname.includes('PhotoGallery') && <Footer userLanguage={userLanguage} />}
    </div >
  );
};

export default PhotoGallery;
